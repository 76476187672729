@import at-theme

@font-face
  font-family: 'Material Icons'
  font-style: normal
  font-weight: 400
  src: url(assets/maticons.ttf) format('truetype')

.material-icons
  font-family: 'Material Icons'
  font-weight: normal
  font-style: normal
  font-size: 24px
  line-height: 1
  letter-spacing: normal
  text-transform: none
  display: inline-block
  white-space: nowrap
  word-wrap: normal
  direction: ltr

@font-face
  font-family: 'Impact'
  font-style: normal
  font-weight: 400
  src: url(assets/impact.ttf) format('truetype')

@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 300
  src: url(assets/roboto300.ttf) format('truetype')

@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  src: url(assets/roboto400.ttf) format('truetype')

@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 500
  src: url(assets/roboto500.ttf) format('truetype')

@font-face
  font-family: 'Constitution'
  font-style: normal
  font-weight: 400
  src: url(assets/usdeclaration.ttf) format('truetype')

@font-face
  font-family: 'Zilla'
  font-style: normal
  font-weight: 300
  src: url(assets/zillaslab300.ttf) format('truetype')

@font-face
  font-family: 'Zilla'
  font-style: normal
  font-weight: 400
  src: url(assets/zillaslab400.ttf) format('truetype')

=font
  font-family: "Zilla", "Avenir Next", sans-serif
  font-size: 16pt
  color: $at-color

.mat-typography
  font-family: "Zilla", "Avenir Next", sans-serif
  font-size: 12pt
  color: $at-color

body
  +font
  margin: 0
  padding: 0
  display: grid

a
  text-decoration: none
  color: $at-link-color

.spin
  animation-timing-function: linear
  animation-name: animation-spin
  animation-duration: 2s
  animation-iteration-count: infinite

@keyframes animation-spin
  100%
    transform : rotate(360deg)

.at-snackbar-error
  background-color: $at-accent-background-color
  color: $at-accent-color
  white-space: pre-line

.at-snackbar-success
  background-color: $at-background-color
  color: $at-color
  white-space: pre-line

.at-center
  margin: auto

.at-float-right
  float: right

.at-horiz-pad
  margin: 0 0 0 5px

.at-shift-down
  position: relative
  top: 5px
  left: -8px

.at-shift-down-plus
  position: relative
  top: 7px
  left: -8px

.at-subtle
  color: $at-subtle-color

.at-mat-icon-small
  font-size: 1rem
  position: relative
  top: 3px

.at-user-id-container
  border: 1px solid $at-border-color
  padding: 3px 8px 3px 8px

//$at-icon-width: min(400px, 80vw)
//$at-icon-height: min(240px, 51vw)
$at-icon-width: min(600px, 80vw)
$at-icon-height: min(385px, 51vw)

.at-icon
  margin: auto
  width: $at-icon-width
  height: $at-icon-height
  background-color: $at-background-color
  display: grid
  border: 10px solid $at-color

.at-icon-flag
  margin: 15px auto auto auto
  max-width: 75%

.at-icon-text
  margin: 0 auto 10px auto
  font-family: Impact, sans-serif
  font-size: 2.6rem
  text-transform: uppercase

.at-top-home-nav
  vertical-align: middle

/* landscape mobile */
@media (min-width: 650px)
  .at-icon-text
    font-size: 3.5rem

/* portrait tablet */
@media (min-width: 750px)
  .at-icon-text
    font-size: 5.0rem

/* landscape tablet, laptop and bigger */
@media (min-width: 1024px)
  .at-icon-text
    font-size: 6.0rem
