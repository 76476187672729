@import '~@angular/material/theming'

@include mat-core()

$at-theme-primary: mat-palette($mat-grey)
$at-theme-accent: mat-palette($mat-green)
$at-theme-warn: mat-palette($mat-deep-orange)

/* LIGHT THEME */
$at-theme: mat-light-theme($at-theme-primary, $at-theme-accent, $at-theme-warn)
$at-light-color: mat-color($at-theme-primary, 800)
$at-light-link-color: mat-color($at-theme-accent, 400)
$at-light-background-color: mat-color($at-theme-primary, A100)
$at-light-border-color: $at-light-color
$at-light-subtle-color: mat-color($at-theme-primary, 500)
$at-light-half-subtle-color: mat-color($at-theme-primary, 600)
$at-light-accent-background-color: mat-color($at-theme-primary, 700)
$at-light-accent-color: mat-color($at-theme-accent, 600)
$at-light-accent-border-color: $at-light-accent-color
$at-light-warn-color: mat-color($at-theme-warn, 400)

$at-color: $at-light-color
$at-link-color: $at-light-link-color
$at-background-color: $at-light-background-color
$at-border-color: $at-light-border-color
$at-subtle-color: $at-light-subtle-color
$at-half-subtle-color: $at-light-half-subtle-color
$at-accent-background-color: $at-light-accent-background-color
$at-accent-color: $at-light-accent-color
$at-accent-border-color: $at-light-accent-border-color
$at-warn-color: $at-light-warn-color

///* DARK THEME */
//@media (prefers-color-scheme: dark)
//  $at-theme: mat-dark-theme($at-theme-primary, $at-theme-accent, $at-theme-warn)
//  $at-dark-color: mat-color($at-theme-primary, 400)
//  $at-dark-link-color: mat-color($at-theme-accent, 400)
//  $at-dark-background-color: mat-color($at-theme-primary, 800)
//  $at-dark-border-color: $at-dark-color
//
//  $at-color: $at-dark-color
//  $at-link-color: $at-dark-link-color
//  $at-background-color: $at-dark-background-color
//  $at-border-color: $at-dark-border-color
//  \:root
//    --at-color: $at-dark-color
//    --at-link-color: $at-dark-link-color
//    --at-background-color: $at-dark-background-color
//    --at-border-color: $at-dark-color


@include angular-material-theme($at-theme)
